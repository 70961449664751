/*
 * Home
 * -----------------------------------------------
*/

/* setion-bottom-absolute-div */
.setion-bottom-absolute-div {
  background: fade(@white-base, 50%) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}