
/*
 * 3.15 -> Trainer
 * -----------------------------------------------
*/

.trainer-item {
	position: relative;
	overflow: hidden;
	.transition(all 0.5s linear 0s);

	&:hover:before {
		height: 100%;
		position: absolute;
		width: 100%;
		background: rgba(0,0,0,0.5);
		content: "";
	}

	.trainer-info {
		height: 100%;
		position: absolute;
		top: 0;
		.transition(all 0.2s linear 0s);
		width: 100%;

		.trainer-biography {
			bottom: 10%;
			.scale(0);
			left: 0;
			right: 0;
			position: absolute;
			margin: auto;
			text-align: center;
			opacity: 0;
			.transition(all 0.5s linear 0s);
			z-index: 3;
		}
	}
	&:hover {
		.trainer-biography {
			opacity: 1;
			.scale(1);
		}
	}

	.social-network {
		text-align: center; 
		position: absolute; 
		color: #fff;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
		top: -50px;
		.transition(all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s);
		z-index: 3;

		li a i {
			width: 30px;
		  	height: 30px;
		  	line-height: 30px;
		  	font-size: 14px;
		  	color: #FFF;
		}
	}

	&:hover {
		.social-network {
			top: 20%;
		}
	}
}

/* Trainer Style 2 */
.box-hover-effect {
	&.effect1 {
		&:hover {
			.thumb {
				img {
					.scale(1.05);
				}
				&:before {
					.rotate(-59deg);
				}
				&:hover {
					.rotate(-0deg);
				} 
			}
		}
	}
}

.trainer-item.style2 {
    overflow: hidden;
    position: relative;
    transition: all 0.5s linear 0s;
    margin-bottom: 30px;
	
	.trainer-biography {
		.scale(1);
	}
	&:hover::before {
		background: transparent;
    	content: "";
    	height: 100%;
    	position: static;
    	width: 100%;
	}

	&:hover {
		.trainer-biography {
			opacity: 1;
			.scale(1);
		}

		.trainer-info {
			bottom: 0;
		}
	}

	.trainer-info {
	    background: #000 none repeat scroll 0 0;
		bottom: -100%;
		display: block;
		height: auto;
		position: absolute;
		top: auto;
		width: 100%;
	    .transition(all 0.3s linear 0s);
			
		.trainer-biography {
			opacity: 1;
		    position: relative;
		    right: auto;
		    left: auto;
		    top: auto;
		    bottom: auto;
		    text-align: center;
		    z-index: 3;
		}
	} 

	.social-network {
		color: #fff;
    	position: relative;
    	text-align: center;
    	top: auto;
    	z-index: 3;

    	&:hover {
			top: auto;
    	}

    	li a i {
    		color: #fff;
		    font-size: 14px;
		    height: 30px;
		    line-height: 30px;
		    width: 30px;
    	}
	}

}

/* Social Network Style 2 */
.team-carousel .styled-icons a i {
  	line-height: 40px;
 }
.bg-img-box  {
    overflow: hidden;

}
.bg-img-box .style3 {
 	top: 55%;
 	padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    position: absolute;
}

 .style3 {
 	opacity: 0;
 	left: -160px;
 	transition: all 0.5s ease-in-out 0s;
}

.bg-img-box:hover .style3 {
 	opacity: 1;
 	left: 0;
}