/*
 * Event
 * -----------------------------------------------
*/
/*schedule-box style*/
.schedule-box {

	&:hover {

		 .thumb {
		 	.overlay {
		 		opacity: 1;
				top: 0;

				a i {
					opacity: 1;
					top: 40%;
				} 
		 	}
		}
	}

	.thumb {
		position: relative;
		overflow-x: hidden;	

		.overlay {
			// background-color: fade(@white-base, 80%);
			bottom: 0;
			left: 0;
			opacity: 0;
			position: absolute;
			right: 0;
			text-align: center;
			-webkit-transition: all 0.3s ease-in-out 0s;
			-o-transition: all 0.3s ease-in-out 0s;
			transition: all 0.3s ease-in-out 0s;
			top: -100%;

			a i {
				background-color: #333;
				color: #fff;
				margin: 0 auto;
				padding: 13px 12px;
				position: absolute;
				text-align: center;
				-webkit-transition: all 0.45s ease-in-out 0s;
				-o-transition: all 0.45s ease-in-out 0s;
				transition: all 0.45s ease-in-out 0s;
				top: 0;
			}
		}
	}

	 .schedule-details {
		border-bottom: 1px solid #bbb;

		 ul li  {
		 	margin: 0;
		 }

		 .title a {
			-webkit-transition: all 0.3s ease-in-out 0s;
			-o-transition: all 0.3s ease-in-out 0s;
			transition: all 0.3s ease-in-out 0s;
		 }
	 }
}


/* event-table */
.table-schedule thead tr th {
	color: #fff;
	font-size: 16px;
	padding: 15px 25px;
	text-transform: uppercase;
}
.table-schedule > tbody > tr {
	background-color: #f3f3f3;
}
.table-schedule > tbody > tr:nth-child(2n+1) {
	background-color: #f9f9f9;
}
.table-schedule tbody tr td {
	border-top: 1px solid #f3f3f3;
	color: #666;
	font-weight: 400;
	padding: 15px 25px;
}
.table-schedule tbody tr td .speaker-thumb {
	float: left;
	margin-right: 15px;
	margin-top: 5px;
	width: 55px;
}
.table-schedule tbody tr td .title {
	font-size: 15px;
	margin-bottom: 0;
	margin-top: 3px;
}
.table-schedule tbody tr td .name {
	font-size: 13px;
	margin: 0;
}
.table-schedule tbody tr td strong {
	color: #666;
	font-weight: 700;
}
.table-schedule .toggle-content {
	cursor: pointer;
}
.table-schedule .session-details {
	display: none;
}