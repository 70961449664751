/*
 * Training Classes
 * -----------------------------------------------
*/

.box-hover-effect.effect1:hover .thumb img {
	.scale(1.05);
}

.box-hover-effect.effect1 .thumb::before {
	.rotate(-59deg);
}
.box-hover-effect.effect1 .thumb:hover {
	.rotate(-0deg);
}