/*
 * Category: Medical
 * -----------------------------------------------
*/

.opening-hours ul li {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 0;
  margin-bottom: 0 !important;
}
.opening-hours ul li span {
  float: left;
}
.opening-hours ul li .value {
  float: right;
}
.box-outlined {
  outline: 7px solid rgba(255, 255, 255, 0.5);
  outline-offset: 7px;
}