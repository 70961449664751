/*
 *  Class Time
 * -----------------------------------------------
*/


.time {
	.translate(-40px, 18px);
	.box-shadow(2px 1px 5px 0px rgba(51,3,0,0));
}

.table-bordered.class-time > thead > tr > th {
    text-align: center;
    padding: 20px 0;
    vertical-align: middle;
}

.table-bordered.class-time tr td:hover {
	// background-color: #EC3642;
	color: #FFF !important;
}

.table.time-schedule tr {
	background: @white-base;
}

.table.time-schedule tr td{
	border-bottom: 1px solid #ddd; 
	border-top: transparent;
	padding: 22px;
	text-transform: uppercase;
	font-weight: bold;
}

.table.table-bordered.class-time td:hover a  {
	color: #FFF;
}