@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        healthzone
Version:        1.0
Last change:    03.29.2016
Primary use:    Royalspa Health & Beauty Business Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";


//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
@import "../css/stroke-gap-icons.css";
@import "../css/flaticon-set-medical.css";
@import "../css/flaticon-set-martial-arts.css";
@import "../css/flaticon-set-gym.css";
@import "../css/flaticon-set-spa.css";
@import "../css/flaticon-set-foods.css";
@import "../css/flaticon-set-yoga.css";
@import "../css/flaticon-set-dental.css";
@import "../css/flaticon-set-boxing.css";
@import "../css/flaticon-set-sports.css";
@import "../css/flaticon-set-nutrition.css";
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700,900);
@import url(https://fonts.googleapis.com/css?family=Droid+Sans:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,200,300,500,600,700,800,900);

 
// Table of contents
@import "less-healthzone/table-of-content.less";

 
// Initialize Variables
@import "less-healthzone/variables.less";
@import "less-healthzone/mixins.less";
// Typography
@import "less-healthzone/typography.less";

// Common Styles
@import "less-healthzone/common.less";
@import "less-healthzone/extra.less";
@import "less-healthzone/overlay.less";

// Header
@import "less-healthzone/header.less";

// Nav
@import "less-healthzone/nav.less";

// Content Blocks
@import "less-healthzone/topbar.less";
@import "less-healthzone/inner-header-title.less";
@import "less-healthzone/vertical-nav.less";
@import "less-healthzone/menu-full-page.less";
@import "less-healthzone/boxed-layout.less";
@import "less-healthzone/form.less";
@import "less-healthzone/side-push-panel.less";
@import "less-healthzone/box-hover-effect.less";
@import "less-healthzone/gallery-isotope.less";
@import "less-healthzone/sliders.less";
@import "less-healthzone/home.less";
@import "less-healthzone/about.less";
@import "less-healthzone/contact.less";
@import "less-healthzone/event.less";
@import "less-healthzone/responsive.less";
@import "less-healthzone/services.less";
@import "less-healthzone/shop.less";
@import "less-healthzone/classes-details.less";
@import "less-healthzone/blog.less";
@import "less-healthzone/class-time.less";
@import "less-healthzone/funfact.less";
@import "less-healthzone/trainer.less";
@import "less-healthzone/traning-class.less";
@import "less-healthzone/calculator.less";
@import "less-healthzone/why-choose.less";
@import "less-healthzone/promotion-box.less";
@import "less-healthzone/hover-effect-collection.less";

// Theme Category
@import "less-healthzone/cat-boxing.less";
@import "less-healthzone/cat-dental.less";
@import "less-healthzone/cat-gym.less";
@import "less-healthzone/cat-medical.less";
@import "less-healthzone/cat-nursing.less";
@import "less-healthzone/cat-nutrition.less";
@import "less-healthzone/cat-sports.less";
@import "less-healthzone/cat-yoga.less";


// Shortcodes
@import "less-healthzone/shortcodes.less";


// Widgets
@import "less-healthzone/widgets.less";


// Footer
@import "less-healthzone/footer.less";